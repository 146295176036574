<template>
  <div>
    <datatable
      :url="transactions.url"
      :ajax="true"
      :ajaxPagination="true"
      :actions="transactions.actions"
      :columns="transactions.columns"
      :filters="transactions.filters"
      :query="transactions.query"
      :fillable="true"
      :selectable="true"
      :dropdown="false"
      sort="created_at"
      order="desc"
      ref="table"
      @selection-update="(data) => updateSelection(data)"
    >
      <template #header>
        <div class="pt-4">
          <search-form
            placeholder="Search users names, emails, phone numbers, bvn, etc."
            class="mb-5"
            v-model="transactions.query"
            @submit="reloadTable"
          />
          <div class="flex items-start flex-wrap xl:flex-no-wrap">
            <div class="flex flex-wrap items-center w-full">
              <span class="processor-filter-title">Filters:</span>
              <template v-for="(filter, index) in transactions.filters">
                <span
                  v-html="filter.title"
                  :key="index"
                  class="processor-filter-item"
                  :class="{ active: currentFilter === filter }"
                  @click.prevent="toggleFilter(filter)"
                />
              </template>
            </div>
          </div>
        </div>
      </template>
    </datatable>
    <div
      class="fixed bottom-20 right-5 sm:right-20 ml-5 sm:mr-20 rounded-lg bg-white p-6 z-10 shadow-lg max-w-500px"
    >
      <div class="font-bold text-sm text-gray-700 mb-4">
        {{ transactions.selected.length }} Transaction{{
          transactions.selected.length !== 1 ? 's' : ''
        }}
        Selected!
      </div>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-4">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <div
        v-if="failures"
        class="flex flex-col gap-5 mb-10 max-h-200px overflow-y-auto"
      >
        <template v-for="(failure, n) in failures">
          <div
            :class="{
              'border-b border-blue-200 pb-5': n < failures.length - 1,
            }"
            :key="n"
          >
            <div class="flex flex-col gap-3 text-xs">
              <span class="mr-3">
                Transaction Reference: {{ failure.request_ref }}
              </span>
              <span>Reason: {{ failure.message }}</span>
            </div>
          </div>
        </template>
      </div>
      <div>
        <button
          class="btn btn-blue btn-sm mr-3"
          @click.prevent="refundSelection"
          :disabled="form.loading || !transactions.selected.length"
        >
          <ion-icon name="return-up-back-outline" class="text-lg mr-2" />
          <sm-loader-white v-if="form.loading" />
          <span v-else>Refund</span>
        </button>
        <button
          class="btn btn-red btn-sm mr-3"
          @click.prevent="failSelection"
          :disabled="form.loading || !transactions.selected.length"
        >
          <ion-icon name="close-circle-outline" class="text-lg mr-2" />
          <sm-loader-white v-if="form.loading" />
          <span v-else>Fail</span>
        </button>
        <button
          class="btn btn-teal btn-sm mr-3"
          @click.prevent="markSelectionAsFailed"
          :disabled="form.loading || !transactions.selected.length"
        >
          <ion-icon name="checkmark-done-outline" class="text-lg mr-2" />
          <sm-loader-white v-if="form.loading" />
          <span v-else>Remove</span>
        </button>
        <button
          class="btn btn-gray btn-sm"
          @click.prevent="clear"
          :disabled="form.loading || !transactions.selected.length"
        >
          <ion-icon name="square-outline" class="text-lg mr-2" />
          <span>Clear</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: this.$options.basicForm(),
      currentFilter: null,
      failures: [],
      successes: [],
      transactions: this.$options.resource([], {
        selected: [],
        refunding: [],
        marking: [],
        query: '',
        url: `${this.$baseurl}/admin/transactions/refundable/bills`,
        columns: [
          { th: 'UserID', name: 'user_id', align: 'right' },
          {
            th: 'Name',
            name: 'name',
            render: (row) => row['user.name'],
            sortable: false,
          },
          {
            th: 'Email',
            name: 'email',
            render: (row) => row['user.email'],
            sortable: false,
          },
          { th: 'Transaction Amount', name: 'amount', align: 'right' },
          {
            th: 'Payment Method',
            name: 'payment_method',
            render: ({ payment_method }) =>
              this.$options.filters.fromSlug(payment_method),
            sortable: false,
          },
          {
            th: 'Transaction Date',
            name: 'bill_transactions.created_at',
            render: ({ created_at }) =>
              this.$options.filters.dateFormat(created_at),
          },
          { th: 'Transaction Reference', name: 'request_ref', sortable: false },
        ],
        actions: [
          {
            text: 'Fail',
            action: this.failTransaction,
            class: 'btn btn-red btn-sm',
            // show: (item) => item.status === 'pending',
            disabled: (item) =>
              this.transactions.refunding.find(
                (i) => i.request_ref === item.request_ref
              ) ||
              this.form.loading ||
              item.payment_method === 'repayment_card',
          },
          {
            text: 'Refund',
            action: this.refundTransaction,
            class: 'btn btn-blue btn-sm',
            show: (item) => ['error', 'failed'].includes(item.status),
            disabled: (item) =>
              this.transactions.refunding.find(
                (i) => i.request_ref === item.request_ref
              ) ||
              this.form.loading ||
              item.payment_method === 'repayment_card',
          },
          {
            text: 'Remove',
            action: this.markAsProcessed,
            class: 'btn btn-teal btn-sm',
            disabled: (item) =>
              this.transactions.marking.find(
                (i) => i.request_ref === item.request_ref
              ) || this.form.loading,
          },
        ],
        filters: [
          { title: 'All', name: 'all' },
          { title: 'Cash', name: 'credpal_cash' },
          { title: 'Credit', name: 'credpal_card' },
          { title: 'Debit Card', name: 'repayment_card' },
        ],
      }),
    };
  },
  methods: {
    async failSelection() {
      const data = { transactions: this.transactions.selected };
      this.reset();
      this.form.loading = true;
      await this.fail(data);
      this.form.loading = false;
    },
    async markSelectionAsFailed() {
      const data = { transactions: this.transactions.selected };
      this.reset();
      this.form.loading = true;
      await this.remove(data);
      this.form.loading = false;
    },
    async refundSelection() {
      const data = { transactions: this.transactions.selected };
      this.reset();
      this.form.loading = true;
      await this.refund(data);
      this.form.loading = false;
    },
    async failTransaction(transaction) {
      const data = { transactions: [transaction.request_ref] };
      this.reset();
      this.setPending(transaction);
      await this.fail(data);
      this.removePending(transaction);
    },
    async refundTransaction(transaction) {
      const data = { transactions: [transaction.request_ref] };
      this.reset();
      this.setPending(transaction);
      await this.refund(data);
      this.removePending(transaction);
    },
    async markAsProcessed(transaction) {
      const data = { transactions: [transaction.request_ref] };
      this.reset();
      this.setPending(transaction);
      await this.remove(data);
      this.removePending(transaction);
    },
    async remove(data) {
      return await this.sendRequest('admin.markAsProcessed.bills', {
        data,
        success: ({ data: { data } }) => this.handleSuccess(data),
        error: (error) => console.log(error),
      });
    },
    async fail(data) {
      return await this.sendRequest('admin.fail.bills', {
        data,
        success: ({ data: { data } }) => this.handleSuccess(data),
        error: (error) => console.log(error),
      });
    },
    async refund(data) {
      return await this.sendRequest('admin.refund.bills', {
        data,
        success: ({ data: { data } }) => this.handleSuccess(data),
        error: (error) => console.log(error),
      });
    },
    clear() {
      this.reset();
      this.$refs.table.clearSelection();
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    toggleFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }

      this.$refs.table.clickedFilter(this.currentFilter);
    },
    removePending(transaction) {
      this.transactions.refunding = this.transactions.refunding.filter(
        (t) => t.request_ref !== transaction.request_ref
      );
      this.$refs.table.renderData();
    },
    setPending(transaction) {
      this.transactions.refunding.push(transaction);
      this.$refs.table.renderData();
    },
    updateSelection({ selection }) {
      this.transactions.selected = selection.map(({ row }) => row.request_ref);
    },
    handleSuccess(data) {
      this.failures = data.failures;
      this.successes = data.successes;
      if (this.successes.length) {
        this.reloadTable();
        return this.$success({
          title: `${this.successes.length} Successful`,
          body: this.failures.length
            ? `${this.failures.length} Failed, Check the confirmation dialog for descriptions.`
            : '0 Failures Recorded',
          button: 'Okay',
        });
      }
      this.$error({
        title: 'No refund was successful',
        body: 'Check the confirmation dialog for descriptions.',
        button: 'Okay',
      });
    },
    reset() {
      this.failures = [];
      this.successes = [];
    },
  },
  mounted() {},
};
</script>
